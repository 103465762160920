import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";


const Page = () => {
  return (
    <LessonLayout
      current_step="5c"
      lesson={"Lesson 2"}
      color={"hs"}
      total_count={10}
      module_title="Separating Fact from Fiction"
      >
      <ContentWrapper>
        <H2>Learn about Clinical Trials</H2>

        <p>When you click the link to the reading, you will see information from the National Institutes of Health (NIH). The NIH is the nation's medical research agency.</p>

        <Button
          action="secondary"
          className="m-2"
          color="indigo"
          href="/files/hs/lesson_2/L2_Clinical_trials_reading_3.pdf"
          icon="download"
          title="Reading 3"
        >
          Reading 3
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;
